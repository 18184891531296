import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { GetAIResultsPayload } from 'app/modules/aiAgentResults/requests';
import { AI_AGENT_RESULTS_QUERY_KEYS } from 'app/modules/aiAgentResults/queries/keys';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { GetAIResultsResponse } from 'app/modules/aiAgentResults/responses';

export const useGetAIResults = (payload: GetAIResultsPayload) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  return useQuery({
    enabled: hasReadAlertsPermission,
    queryFn: () => {
      return post<GetAIResultsResponse>(
        '/ai-investigations/ai-agent-outputs',
        payload,
      );
    },
    queryKey: AI_AGENT_RESULTS_QUERY_KEYS.getAIResults(payload),
    refetchInterval: (query) => {
      if (!query.state.data?.is_reviewing) {
        return false;
      }
      return 2_000;
    },
  });
};
